import React, { useEffect } from "react"
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
} from "antd"

import { useIntl } from "gatsby-plugin-intl"
import formatService from "../../services/formatService"
import Template from "../../layouts/base"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import withAuth from "../../components/withAuthHoc"
import authFetch from "../../services/network"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import useStateObject from "../../hooks/useStateObject"

const { confirm } = Modal

const AlarmsList = () => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    data: [],
    showData: null,
    modalVisible: false,
  })

  const fetchIngestAlarms = () => {
    authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests/alarms/`)
      .then(response => {
        handleIngestAlarmsResponse(response)
      })
      .catch(reason => {
        setState({ showData: null })
        message.error(
          `${intl.formatMessage({
            id: "page-alarms-alarms-get-ko",
          })} Error: ${reason}`
        )
      })
  }

  useEffect(() => {
    fetchIngestAlarms()
  }, [])

  const handleIngestAlarmsResponse = response => {
    if (response.status !== 200) {
      throw response.status
    } else {
      response.json().then(data => {
        setState({ data, showData: data })
      })
    }
  }

  const deleteIngestAlarms = () => {
    message.info(intl.formatMessage({ id: "page-alarms-deleting-alarms" }))
    authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests/alarms/`, {
      method: "DELETE",
    })
      .then(_ =>
        message.info(
          intl.formatMessage({ id: "page-alarms-alarms-deleted-ok" })
        )
      )
      .catch(reason => {
        message.error(
          `${intl.formatMessage({
            id: "page-alarms-alarms-deleted-ko",
          })} Error: ${reason}`
        )
      })
  }

  const handleFilter = e => {
    const value = e.currentTarget.value
    let showData
    if (value) {
      showData = (state.data || []).filter(
        ({ id = "", alias = "" }) =>
          id.match(value.toLowerCase()) || alias.match(value.toLowerCase())
      )
    } else {
      showData = (state.data || []).slice()
    }
    setState({ showData })
  }

  const handleDeleteAlarmsButtonClick = () => {
    confirm({
      title: intl.formatMessage({ id: "modal-are-you-sure" }),
      content: intl.formatMessage({
        id: "page-alarms-modal-delete-alarms-content",
      }),
      okText: intl.formatMessage({ id: "modal-ok" }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: "modal-ko" }),
      onOk() {
        deleteIngestAlarms()
      },
    })
  }

  const getIngestAlarmIdFilterValues = () => {
    return Array.from(
      new Set((state.showData || []).map(data => data.id))
    ).map(uniqueId => ({ text: uniqueId, value: uniqueId }))
  }

  const getIngestAlarmAliasFilterValues = () => {
    return Array.from(
      new Set((state.showData || []).map(data => data.alias))
    ).map(uniqueAlias => ({ text: uniqueAlias, value: uniqueAlias }))
  }

  const ALIGN = "left"
  const ALIGN_CENTERED = "center"
  const columns = [
    {
      title: intl.formatMessage({ id: "models-ingestion" }),
      dataIndex: "id",
      key: "id",
      align: ALIGN,
      colSpan: 2,
      sorter: (a, b) => a.id.localeCompare(b.id),
      sortDirections: ["ascend", "descend"],
      filters: getIngestAlarmIdFilterValues(),
      filterMultiple: true,
      ellipsis: true,
      onFilter: (value, record) => record.id === value,
      render: text => {
        return {
          children: text,
          props: {
            colSpan: 2,
          },
        }
      },
    },
    {
      title: intl.formatMessage({ id: "models-ingestion-alias" }),
      dataIndex: "alias",
      key: "alias",
      align: ALIGN,
      colSpan: 2,
      ellipsis: true,
      sorter: (a, b) => a.alias.localeCompare(b.alias),
      sortDirections: ["ascend", "descend"],
      filters: getIngestAlarmAliasFilterValues(),
      filterMultiple: true,
      onFilter: (value, record) => record.alias === value,
      render: text => {
        return {
          children: text,
          props: {
            colSpan: 2,
          },
        }
      },
    },
    {
      title: intl.formatMessage({ id: "relevance" }),
      dataIndex: "severity",
      key: "severity",
      align: ALIGN_CENTERED,
      colSpan: 1,
      render: severity => {
        return {
          children: <Tag key={severity}>{severity}</Tag>,
          props: {
            colSpan: 1,
          },
        }
      },
    },
    {
      title: intl.formatMessage({ id: "type" }),
      dataIndex: "type",
      key: "type",
      colSpan: 1,
      ellipsis: true,
      align: ALIGN_CENTERED,
      render: type => ({ children: type, props: { colSpan: 1 } }),
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: intl.formatMessage({ id: "description" }),
      dataIndex: "message",
      key: "message",
      align: ALIGN,
      colSpan: 2,
      ellipsis: true,
      sorter: (a, b) => a.id.localeCompare(b.id),
      sortDirections: ["ascend", "descend"],
      render: desc => ({
        children: <div>{desc}</div>,
        props: { colSpan: 2 },
      }),
    },
    {
      title: intl.formatMessage({ id: "created-at" }),
      dataIndex: "createdAt",
      key: "createdAt",
      align: ALIGN_CENTERED,
      colSpan: 2,
      ellipsis: true,
      render: text => ({
        children: formatService.formatDate("short", text),
        props: { colSpan: 2 },
      }),
      sorter: (a, b) => {
        const dateA = new Date(a)
        const dateB = new Date(b)

        return a === b ? 0 : dateA.getTime() > dateB.getTime() ? 1 : -1
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: intl.formatMessage({ id: "count" }),
      dataIndex: "count",
      key: "count",
      align: ALIGN_CENTERED,
      colSpan: 1,
      ellipsis: true,
      render: count => ({ children: count, props: { colSpan: 1 } }),
      sorter: (a, b) => a.count - b.count,
      sortDirections: ["ascend", "descend"],
    },
  ]

  const loading = !state.showData || state.showData.length === undefined

  return (
    <Template selected={["alarms", "alarms-index"]}>
      <CustomBreadcrumb
        crumbs={[intl.formatMessage({ id: "menu-alarms-management" })]}
      />
      <Row>
        <Col className="intro">
          <h1>{intl.formatMessage({ id: "models-alarms-list" })}:</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <section
            className="alarms-header"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "page-alarms-html-header",
              }),
            }}
          />
        </Col>
      </Row>
      <div className="content">
        <Row type="flex" justify="start" align="center">
          <Col span={12}>
            <Form.Item layout="horizontal">
              <Input
                style={{ height: "40px" }}
                required
                name="filter"
                onChange={handleFilter}
                placeholder={intl.formatMessage({
                  id: "page-alarms-search-bar",
                })}
                suffix={
                  <Tooltip
                    title={intl.formatMessage({
                      id: "page-alarms-search-bar-tooltip",
                    })}
                  >
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col
            span={12}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            {checkPermissions(["alarm:delete"]) ? (
              <Form.Item>
                <Button
                  onClick={handleDeleteAlarmsButtonClick}
                  type="primary"
                  style={{ height: "40px" }}
                >
                  {intl.formatMessage({
                    id: "page-alarms-delete-alarms-button",
                  })}
                </Button>
              </Form.Item>
            ) : null}
          </Col>
          <Col span={24}>
            <Table
              size="small"
              loading={loading}
              columns={columns}
              dataSource={state.showData}
              pagination={{ defaultPageSize: 8 }}
              locale={{
                filterTitle: intl.formatMessage({ id: "filters" }),
                filterConfirm: intl.formatMessage({ id: "apply" }),
                filterReset: intl.formatMessage({ id: "reset" }),
                emptyText: intl.formatMessage({ id: "no-data" }),
              }}
            />
          </Col>
        </Row>
      </div>
    </Template>
  )
}

export default withAuth(AlarmsList)
